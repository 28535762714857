exports.onInitialClientRender = () => {
  window.scrollTo(0, 0)

  //　ローディングアニメーション制御
  document.getElementById("loader-img").style.opacity = 1
  const loader = document.getElementById("___loader")
  let fadeout = new Promise((resolve) => {
    setTimeout(() => {
      loader.style.opacity = 0
      resolve(true)
    }, 1500)
  })

  fadeout.then(() => {
    setTimeout(function() {
      loader.style.display = "none"
    }, 400)
  })
}

exports.onClientEntry = () => {
  // IE対策
  require(`intersection-observer`)

  const ua = navigator.userAgent
  const pathname = window.location.pathname
  if( ua.indexOf('Trident') !== -1 && pathname !== "/brError/" ) {
    window.location.href = "/brError/"
  }
}

